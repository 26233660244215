<template>
  <div class="info-message bg-white border border-blue rounded-lg position-relative">
    <div class="svg-container bg-white shadow-sm p-1">
      <InfoIcon class="text-blue" />
    </div>

    <div class="c-content" v-html="message" />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfoIcon from '../assets/images/svgs/icons/info.svg';

@Component({
  components: {
    InfoIcon,
  },
})
export default class InfoMessage extends Vue {
  @Prop({ required: true }) message;
}
</script>
